<template>
  <div>
    <div class="main-slide"
         :style="{'background-image': `url('${backgroundImage}')`}">
      <div class="main-content">
        <h2 class="main-title">{{ title }}</h2>
        <div class="text">
          <p>{{ description }}</p>
        </div>
        <router-link :to="link" class="button-color lg">Пройти опрос</router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'QuestionnaireSlide',

  props: {
    title: String,
    description: String,
    link: String,
    backgroundImage: String,
  },
};
</script>

<style scoped>

</style>
