import HTTP from './default';

export default {
    getSurveysSlider() {
        return HTTP({
            url: '/survey/',
            method: 'GET',
            headers: {
                Authorization: `Token ${localStorage.getItem('userToken')}`,
            },
        });
    },

    getSurvey(id) {
        return HTTP({
            url: `/survey/${id}/`,
            method: 'GET',
            headers: {
                Authorization: `Token ${localStorage.getItem('userToken')}`,
            },
        });
    },

    setResult(id, surveyResult) {
        return HTTP({
            url: `/survey_result/${id}/`,
            method: 'PATCH',
            headers: {
                Authorization: `Token ${localStorage.getItem('userToken')}`,
            },
            data: surveyResult,
        });
    },

    setComplete(id) {
        return HTTP({
            url: `/survey_result/${id}/complete/`,
            method: 'POST',
            headers: {
                Authorization: `Token ${localStorage.getItem('userToken')}`,
            },
        });
    },

    getResult(id) {
        return HTTP({
            url: `/survey_result/${id}/`,
            method: 'GET',
            headers: {
                Authorization: `Token ${localStorage.getItem('userToken')}`,
            },
        });
    },
};
