<template>
  <div class="checkbox-item">
    <label class="checkbox-circle">
      <input :disabled="isSelectionCompleted && !isSelected"
             :value="value"
             @click="toggle"
             type="checkbox"
             v-model="model">
      <span>{{ theme }}</span>
    </label>
    <div v-if="forUser" class="marker-item hollow"> {{ forUser }} </div>
  </div>
</template>

<script>
export default {
  name: 'Checkbox',

  data() {
    return {
      isSelected: false,
    };
  },

  props: {
    value: String,
    checked: null,
    theme: String,
    forUser: String,
    isSelectionCompleted: Boolean,
  },

  model: {
    prop: 'checked',
    event: 'change',
  },

  computed: {
    model: {
      get() {
        return this.checked;
      },
      set(value) {
        this.$emit('change', value);
      },
    },
  },

  methods: {
    toggle() {
      this.isSelected = !this.isSelected;
    },
  },
};
</script>

<style scoped>

</style>