<template>
  <transition name="fade">
    <div v-if="isLoaded">
      <main class="body">

        <section class="section">
          <div class="content">
            <transition name="fade">
              <div v-if="surveysSlider" class="main-slider-wrap">
                <Slick :options="carouselSettings" class="main-slider">
                  <questionnaire-slide
                      v-for="(survey, index) in surveysSlider"
                      :backgroundImage="survey.slider_bg_image"
                      :key="index"
                      :title="survey.title"
                      :link="`/survey/${survey.slug}-${survey.id}`"
                      :description="survey.description"
                  />
                </Slick>
              </div>
            </transition>
          </div>
        </section>

        <section
            v-if="activePrograms && activePrograms.length"
            class="section"
        >
          <div class="content">
            <div class="faq-group">
              <ProgramSlide
                  v-for="program in activePrograms"
                  :key="program.id"
                  :program="program"
              />
            </div>
          </div>
        </section>

        <section class="section">
          <div class="content">
            <div class="filter-block">
              <div class="grid">
                <div class="box">
                  <div class="cells">
                    <div class="cell">
                      <h3 class="section-title">Все курсы</h3>
                    </div>
                    <div class="cell">
                      <div class="filter-item" @click="showChooseThemeModal = true">
                        <button class="button-control">
                        <span class="icon-item">
                            <img src="@/assets/img/icons/icon-filter.svg" alt="">
                            <img src="@/assets/img/icons/icon-filter-color.svg" alt="">
                        </span>
                          Темы обучения
                          <span v-if="selectedThemes && selectedThemes.length" class="dot yellow"></span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="box">
                  <div :class="{'active': isOpenedSearch}" class="filter-search-block">
                    <search
                        @show="isOpenedSearch = !isOpenedSearch"
                        v-model="searchValue"
                        @input="getCourses"
                    />
                    <single-select
                        :title="'Сортировать'"
                        @input="getCourses"
                        :options="[
                                   {title: 'По дате добавления', value: '-date_create'},
                                   {title: 'По алфавиту', value: 'name'}
                                 ]"
                        v-model="sortType"
                    />
                    <single-select
                        :title="'Уровень'"
                        @input="getCourses"
                        :options="[
                                   {title: 'Все', value: ''},
                                   ...tags,
                                 ]"
                        v-model="filterTag"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="course-card-group">
              <transition name="fade">
                <div v-if="courses && courses.length" class="grid">
                  <course-card
                      v-for="(course, index) in courses" :key="index"
                      :isSigned="course.is_signed"
                      :tag="course.tag"
                      :title="course.name"
                      :theme="course.themes && course.themes[0]"
                      :progressPercent="course.progress_percent"
                      :imageUrl="course.image"
                      :link="`/course/${course.slug}-${course.id}`"
                  />
                </div>
              </transition>
              <transition name="fade">
                <div class="center" v-if="coursesResponse && coursesResponse.next">
                  <button class="button-default"
                          v-if="!isLoadingMore"
                          @click="loadMore(coursesResponse.next)">
                    Загрузить еще
                  </button>
                  <button class="button-default" v-else>
                    <button-loader/>
                  </button>
                </div>
              </transition>
            </div>
          </div>
        </section>
      </main>

      <footer class="footer"></footer>

      <template v-show="showChooseThemeModal && themes">
        <choose-theme-modal
            :show="showChooseThemeModal"
            @close="showChooseThemeModal = false"
            @selected="selectTheme"
        />
      </template>

    </div>
  </transition>
</template>

<script>
import Search from '@/components/Search';
import SingleSelect from '@/components/SingleSelect';
import ChooseThemeModal from '@/components/modals/ChooseThemeModal';
import CourseCard from '@/components/CourseCard';
import ButtonLoader from '@/components/ButtonLoader';
import ClickOutside from 'vue-click-outside';
import Slick from 'vue-slick';
import QuestionnaireSlide from '@/components/QuestionnaireSlide';
import Teachbase from '@/api/teachbase';
import Survey from '@/api/survey';
import {sortArray} from '@/core/helpers/sortArray';
import ProgramSlide from '@/components/ProgramSlide';
import Profile from '@/api/profile';

export default {
  name: 'Courses',

  data() {
    return {
      isLoaded: false,
      courses: [],
      themes: null,
      tags: null,
      surveysSlider: null,
      searchValue: '',
      sortType: '-date_create',
      filterTag: '',
      showChooseThemeModal: false,
      isLoadingMore: false,
      carouselSettings: {
        fade: true,
        infinite: true,
        easing: 'linear',
        arrows: true,
        dots: true,
        autoplay: false,
        autoplaySpeed: 3000,
        pauseOnFocus: false,
        pauseOnHover: false,
        speed: 600,
        draggable: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        responsive: [
          {
            breakpoint: 640,
            settings: {
              adaptiveHeight: true,
            },
          },
        ],
      },
      selectedThemes: null,
      questionnaires: null,
      showcaseCourses: null,
      isOpenedSearch: false,
      coursesResponse: null,
      activePrograms: null,
    };
  },

  async created() {
    await this.getSelectedThemes();
    await this.getTags();
    await this.getSurveysSlider();
    await this.getActivePrograms();
    this.isLoaded = true;
    await this.getCourses();
  },

  computed: {},

  watch: {},

  methods: {
    sortArray,

    async getCourses() {
      const params = {
        ordering: this.sortType,
        search: this.searchValue,
        page: 1,
        tag: this.filterTag,
        themes: this.selectedThemes?.toString(),
      };

      const response = await Teachbase.getAllCourses(params);
      this.coursesResponse = response.data;
      this.courses = response.data.results;
    },

    async loadMore(urlOfNextPage) {
      this.isLoadingMore = true;

      const url = new URL(urlOfNextPage);
      const pageNumber = url.searchParams.get('page');

      const params = {
        ordering: this.sortType,
        search: this.searchValue,
        page: pageNumber,
        tag: this.filterTag,
        themes: this.selectedThemes?.toString(),
      };

      const response = await Teachbase.getAllCourses(params);
      this.coursesResponse = response.data;
      this.courses = this.courses.concat(response.data.results);

      this.isLoadingMore = false;
    },

    async getTags() {
      const response = await Teachbase.getTags();
      this.tags = response.data.map(tag => {
        return {
          title: tag.replace('Уровень Подготовки: ', ''),
          value: tag,
        };
      });
    },

    async getSurveysSlider() {
      const response = await Survey.getSurveysSlider();
      this.surveysSlider = response.data;
    },

    async getSelectedThemes() {
      this.selectedThemes = JSON.parse(localStorage.getItem('checked')) || [];
    },

    selectTheme(value) {
      this.selectedThemes = value;
      this.getCourses();
    },

    async getActivePrograms() {
      const params = {
        is_completed: false,
      };
      let response = await Profile.getAllPrograms(params);
      this.activePrograms = response.data?.results.filter(program => program?.courses?.length);
    },
  },

  directives: {
    ClickOutside,
  },

  components: {
    Search,
    SingleSelect,
    ChooseThemeModal,
    CourseCard,
    ButtonLoader,
    Slick,
    QuestionnaireSlide,
    ProgramSlide,
  },
};
</script>

<style lang="scss" scoped>
</style>
