<template>
  <transition name="fade">
    <div class="modal"
         id="modal-choose-theme"
         v-if="show">
      <div class="modal-container">
        <div class="modal-inner" @click.self="$emit('close')">
          <div class="modal-body">
            <div class="modal-close" @click="$emit('close')"></div>
            <div class="modal-content">

              <h4 class="modal-title">Темы обучения</h4>

              <div class="search-block">
                <form @submit.prevent>
                  <button class="search-button">
                    <div class="icon-item">
                      <img src="@/assets/img/icons/icon-search.svg" alt="">
                      <img src="@/assets/img/icons/icon-search-color.svg" alt="">
                    </div>
                  </button>
                  <input v-model="searchValue"
                         type="text"
                         class="field-value"
                         placeholder="Начните вводить тему">
                  <div class="search-clear" @click="searchValue = ''">
                    <img src="@/assets/img/icons/icon-remove.svg" alt="">
                    Очистить
                  </div>
                </form>
              </div>
              <form @submit.prevent>
                <div class="choose-theme-wrap">
                  <div v-if="list" class="choose-theme-group">
                    <Checkbox v-for="(element, index) in filterThemes"
                              :key="index"
                              :value="element.id"
                              :theme="element.theme"
                              v-model="checked"/>
                  </div>
                </div>
                <div class="button-group">
                  <button class="button-color" @click="choose" >Выбрать</button>
                  <button class="button-empty" @click="reset">Сбросить</button>
                </div>
              </form>

            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import ClickOutside from 'vue-click-outside';
import Checkbox from '../ui/Checkbox';
import Teachbase from '../../api/teachbase.js';
import {sortArray} from '@/core/helpers/sortArray';

export default {
  name: 'ChooseThemeModal',
  props: {
    show: Boolean,
  },

  data() {
    return {
      searchValue: '',
      list: null,
      checked: [],
    };
  },

  async created() {
    const response = await Teachbase.getThemes();
    this.list = response.data.map(theme => {
      return {
        id: theme,
        theme: theme,
      };
    });
    this.list = sortArray(this.list, {by: 'theme', order: 'asc', });
  },

  mounted() {
    this.checked = JSON.parse(localStorage.getItem('checked')) || [];
  },

  computed: {
    filterThemes() {
      return this.list.filter(element => element.theme.toLowerCase().includes(this.searchValue.toLowerCase()));
    },
  },

  beforeDestroy() {
  },

  watch: {
    show(value) {
      if (value) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = 'auto';
      }
    },
  },

  methods: {
    choose(){
      let parsed = JSON.stringify(this.checked);
      localStorage.setItem('checked', parsed);
      this.$emit('selected', this.checked);
      this.$emit('close');
    },

    reset() {
      localStorage.removeItem('checked');
      this.checked = [];
      this.$emit('selected', this.checked);
      this.$emit('close');
    },
  },

  directives: {
    ClickOutside,
  },

  components: {
    Checkbox,
  },
};
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity .4s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
