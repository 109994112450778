<template>
  <div id="circleG">
    <div id="circleG_1" class="circleG"></div>
    <div id="circleG_2" class="circleG"></div>
    <div id="circleG_3" class="circleG"></div>
  </div>
</template>

<script>
export default {
  name: 'ButtonLoader',
  props: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
#circleG {
  width: 45px;
  margin: 0 25px;
}

.circleG {
  background-color: rgb(255, 255, 255);
  float: left;
  height: 8px;
  margin-left: 5px;
  width: 8px;
  animation-name: bounce_circleG;
  animation-duration: 2.24s;
  animation-iteration-count: infinite;
  animation-direction: normal;
  border-radius: 6px;
}

#circleG_1 {
  animation-delay: 0.45s;
}

#circleG_2 {
  animation-delay: 1.05s;
}

#circleG_3 {
  animation-delay: 1.35s;
}


@keyframes bounce_circleG {
  0% {
  }

  50% {
    background-color: rgb(0, 0, 0);
  }

  100% {
  }
}
</style>
